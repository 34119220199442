<template>
  <v-card outlined class="pa-8 rounded-lg box-card-shadow">
    <div class="text-h3 text-md-h2 font-weight-bold mb-8">
      Solicita tu cotización
    </div>
    <div>
      <v-form id="request-form" ref="form" v-model="valid" lazy-validation>
        <div v-if="user">
          <v-text-field
            flat
            dense
            outlined
            required
            :value="user.name"
            hide-details="auto"
            single-line
            readonly
            color="secondary"
            class="rounded-lg mb-3 mb-md-6 v-input--is-focused"
            background-color="transparent"
            :style="[
              user.name && {
                'background-color': 'rgba(98, 37, 130, 0.1) !important',
              },
            ]"
          >
          </v-text-field>
          <v-text-field
            flat
            dense
            outlined
            required
            :value="user.email"
            hide-details="auto"
            single-line
            readonly
            color="secondary"
            class="rounded-lg mb-3 mb-md-6 v-input--is-focused"
            background-color="transparent"
            :style="[
              user.email && {
                'background-color': 'rgba(98, 37, 130, 0.1) !important',
              },
            ]"
          >
          </v-text-field>
          <v-text-field
            flat
            dense
            outlined
            required
            :value="user.phone"
            hide-details="auto"
            single-line
            readonly
            color="secondary"
            class="rounded-lg mb-3 mb-md-6 v-input--is-focused"
            background-color="transparent"
            :style="[
              user.phone && {
                'background-color': 'rgba(98, 37, 130, 0.1) !important',
              },
            ]"
          >
          </v-text-field>
        </div>

        <div>
          <v-menu
            v-model="date.modelMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="computedDateFormatted"
                readonly
                v-bind="attrs"
                outlined
                dense
                class="rounded-lg mb-3 mb-md-6 v-input--is-focused"
                type="text"
                :style="[
                  date.model && {
                    'background-color': 'rgba(98, 37, 130, 0.1) !important',
                  },
                ]"
                clearable
                clear-icon="mdi-close secondary--text"
                placeholder="Fecha del evento"
                hide-details="auto"
                :rules="date.rules"
                @click:clear="date.model = ''"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date.model"
              color="secondary"
              no-title
              prev-icon="fa-chevron-left secondary--text"
              next-icon="fa-chevron-right secondary--text"
              show-adjacent-months
              :min="newUnixDay"
              @input="date.modelMenu = false"
            ></v-date-picker>
          </v-menu>

          <v-row no-gutters justify="space-around">
            <v-col cols="7" class="pr-2 pr-sm-3 pr-md-0">
              <v-text-field
                v-model="dateTime.modelHour"
                v-mask="'##:##'"
                flat
                dense
                outlined
                required
                hide-details="auto"
                type="text"
                color="secondary"
                class="rounded-lg mb-3 mb-md-6 v-input--is-focused"
                background-color="transparent"
                validate-on-blur
                :style="[
                  isHourValid && {
                    'background-color': 'rgba(98, 37, 130, 0.1) !important',
                  },
                ]"
                clearable
                clear-icon="mdi-close secondary--text"
                :placeholder="dateTime.textHour"
                :rules="dateTime.hourRules"
                @blur="validateHour"
              >
              </v-text-field>
            </v-col>
            <v-col cols="5" class="pl-0 pl-md-3">
              <v-select
                v-model="dateTime.modelTime"
                :items="dateTime.options"
                append-icon="fa-caret-down secondary--text"
                single-line
                outlined
                hide-details="auto"
                solo
                dense
                flat
                required
                color="secondary"
                class="mb-3 mb-md-6 rounded-lg v-input--is-focused secondary--text text-subtitle-1"
                item-color="secondary"
                :background-color="
                  dateTime.modelTime ? 'rgba(98, 37, 130, 0.1)' : ''
                "
                :menu-props="{
                  bottom: true,
                  closeOnContentClick: true,
                  allowOverflow: true,
                  offsetY: true,
                  rounded: 'md',
                  transition: 'slide-y-transition',
                }"
                :placeholder="dateTime.textTime"
                :rules="dateTime.ampmRules"
              >
              </v-select>
            </v-col>
          </v-row>
          <!-- <v-text-field
            v-model="city.model"
            flat
            type="text"
            dense
            outlined
            required
            hide-details="auto"
            single-line
            color="secondary"
            clearable
            clear-icon="mdi-close secondary--text"
            validate-on-blur
            class="rounded-lg mb-3 mb-md-6 v-input--is-focused"
            background-color="transparent"
            :style="[
              city.model && {
                'background-color': 'rgba(98, 37, 130, 0.1) !important',
              },
            ]"
            :placeholder="city.text"
            :rules="city.rules"
          >
          </v-text-field> -->
          <v-autocomplete
            v-model="state.model"
            :items="states"
            :no-data-text="state.noDataText"
            single-line
            outlined
            hide-details="auto"
            solo
            dense
            flat
            required
            color="secondary"
            item-color="secondary"
            :background-color="state.model ? 'rgba(98, 37, 130, 0.1)' : ''"
            append-icon="fa-caret-down secondary--text"
            autocomplete="null"
            :menu-props="{
              bottom: true,
              closeOnContentClick: true,
              allowOverflow: true,
              offsetY: true,
              rounded: 'md',
              transition: 'slide-y-transition',
            }"
            :placeholder="state.text"
            :rules="state.rules"
            class="mb-3 mb-md-6 rounded-lg v-input--is-focused secondary--text text-subtitle-1"
            @change="stateUpdated"
          >
          </v-autocomplete>
          <v-autocomplete
            v-model="city.model"
            :items="citySelect"
            :no-data-text="
              state.model ? city.noDataText : 'Seleccione un estado'
            "
            single-line
            outlined
            hide-details="auto"
            solo
            dense
            flat
            required
            color="secondary"
            item-color="secondary"
            :background-color="city.model ? 'rgba(98, 37, 130, 0.1)' : ''"
            autocomplete="null"
            :menu-props="{
              bottom: true,
              closeOnContentClick: true,
              allowOverflow: true,
              offsetY: true,
              rounded: 'md',
              transition: 'slide-y-transition',
            }"
            append-icon="fa-caret-down secondary--text"
            :placeholder="city.text"
            :rules="city.rules"
            class="mb-3 mb-md-6 rounded-lg v-input--is-focused secondary--text text-subtitle-1"
          >
          </v-autocomplete>
          <v-text-field
            v-model="eventAddress.model"
            flat
            type="text"
            dense
            outlined
            required
            hide-details="auto"
            single-line
            color="secondary"
            clearable
            clear-icon="mdi-close secondary--text"
            validate-on-blur
            class="rounded-lg mb-3 mb-md-6 v-input--is-focused"
            background-color="transparent"
            :style="[
              eventAddress.model && {
                'background-color': 'rgba(98, 37, 130, 0.1) !important',
              },
            ]"
            :placeholder="eventAddress.text"
            maxlength="255"
            :rules="eventAddress.rules"
          >
          </v-text-field>
          <v-text-field
            v-model="eventType.model"
            flat
            type="text"
            dense
            outlined
            required
            hide-details="auto"
            single-line
            color="secondary"
            clearable
            clear-icon="mdi-close secondary--text"
            validate-on-blur
            class="rounded-lg mb-3 mb-md-6 v-input--is-focused"
            background-color="transparent"
            :style="[
              eventType.model && {
                'background-color': 'rgba(98, 37, 130, 0.1) !important',
              },
            ]"
            :placeholder="eventType.text"
            maxlength="40"
            :rules="eventType.rules"
          >
          </v-text-field>
          <v-text-field
            v-model.number="peopleQuantity.model"
            flat
            dense
            outlined
            required
            hide-details="auto"
            pattern="^[0-9]+"
            type="number"
            min="0"
            step="1"
            single-line
            color="secondary"
            class="rounded-lg mb-3 mb-md-6 v-input--is-focused"
            background-color="transparent"
            :style="[
              isQuantityValid && {
                'background-color': 'rgba(98, 37, 130, 0.1) !important',
              },
            ]"
            clearable
            clear-icon="mdi-close secondary--text"
            validate-on-blur
            :placeholder="peopleQuantity.text"
            :rules="peopleQuantity.rules"
            @input="validateQuantity"
          >
          </v-text-field>
          <v-textarea
            v-model="clientComment.model"
            solo
            flat
            dense
            clearable
            clear-icon="mdi-close secondary--text"
            no-resize
            outlined
            hide-details="auto"
            :rows="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? 4 : 6"
            color="secondary"
            auto-grow
            class="rounded-lg mb-3 v-input--is-focused"
            background-color="transparent"
            :style="[
              isClientCommentValid && {
                'background-color': 'rgba(98, 37, 130, 0.1) !important',
              },
            ]"
            validate-on-blur
            :placeholder="clientComment.text"
            maxlength="255"
            :rules="clientComment.rules"
            @input="validateClientComment"
          >
          </v-textarea>
        </div>
        <Button
          text="Solicitar"
          block
          :disabled="loading"
          class="my-7"
          aria-label="Enviar solicitud de cotización"
          @event="handleRequestSentModal()"
        />
        <!-- <v-row no-gutters>
          <v-col cols="9" class="pr-2">Tiempo de respuesta</v-col>
          <v-col cols="3" align="right">
            {{ responseTime }}
          </v-col>
        </v-row> -->
      </v-form>
    </div>
    <ModalLoading :dialog="loading" />
    <ModalRequestSent :dialog="requestSentModal" @closeModal="handleModal" />
  </v-card>
</template>
<script>
import ModalLoading from "@/components/Shared/Modal/ModalLoading.vue";
import ModalRequestSent from "@/components/User/Shared/Modal/ModalRequestSent.vue";
import Button from "@/components/Shared/Button.vue";
import { CREATE_EVENT } from "@/graphql/mutations";
import { useMutation } from "@/graphql/index";
import { mapState } from "vuex";
import {
  quantityInput,
  fillInput,
  stateRulesSelect,
  cityRulesSelect,
  timeRules,
  optionalTextAreaRules,
} from "@/Utils/rules.js";
import {
  convertDateToUnix,
  convertUnixToYYYYMMDD,
} from "@/Utils/dateConverter.js";
import { states, getCitiesByState } from "@/Utils/locations.js";
import VueMask from "v-mask";
import Vue from "vue";

Vue.use(VueMask);
export default {
  components: {
    Button,
    ModalLoading,
    ModalRequestSent,
  },
  props: {
    serviceId: { type: Number, required: true },
    serviceCategory: { type: String, required: true },
    disableBtn: {
      type: Boolean,
      default: false,
    },
    paymentServiceDeadline: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    date: {
      text: "Fecha del evento",
      modelMenu: false,
      model: "",
      rules: fillInput,
      newUnixDay: "",
      servicePaymentDeadline: "",
    },
    dateTime: {
      textHour: "00:00",
      textTime: "AM",
      modelHour: "",
      modelTime: "",
      options: ["AM", "PM"],
      hourRules: timeRules,
      ampmRules: fillInput,
    },
    state: {
      text: "Estado del evento",
      model: "",
      noDataText: "Estado no encontrado",
      rules: stateRulesSelect,
    },
    city: {
      text: "Ciudad del evento",
      model: "",
      noDataText: "Ciudad no encontrada",
      rules: cityRulesSelect,
    },
    eventAddress: {
      text: "Dirección del evento",
      model: "",
      rules: fillInput,
    },
    eventType: {
      text: "Tipo de evento",
      model: "",
      rules: fillInput,
    },
    peopleQuantity: {
      text: "Número de invitados",
      model: null,
      rules: quantityInput,
    },
    clientComment: {
      text: "Comentarios",
      model: "",
      rules: optionalTextAreaRules,
    },
    states: states,
    valid: true,
    requestSentModal: false,
    isHourValid: false,
    isQuantityValid: false,
    isClientCommentValid: false,
    loading: false,
  }),
  computed: mapState({
    user(state) {
      return state.user;
    },
    computedDateFormatted() {
      return this.formatDate(this.date.model);
    },
    citySelect() {
      return getCitiesByState(this.state.model);
    },
  }),
  created() {
    this.servicePaymentDeadline = this.paymentServiceDeadline;
    this.dateUnixToString();
  },
  methods: {
    stateUpdated() {
      this.city.model = "";
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    async handleRequestSentModal() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        const unixDate = convertDateToUnix(
          this.date.model,
          this.dateTime.modelHour,
          this.dateTime.modelTime
        );
        const props = {
          city: this.city.model,
          state: this.state.model,
          date: unixDate,
          serviceId: parseInt(this.serviceId),
          userId: parseInt(this.user.id),
          quotationRequest: {
            category: this.serviceCategory,
            eventAddress: this.eventAddress.model,
            eventDate: unixDate,
            eventType: this.eventType.model,
            peopleQuantity: parseInt(this.peopleQuantity.model),
            clientComment: this.clientComment.model,
          },
        };

        const { data, errors } = await useMutation(CREATE_EVENT, props);
        if (data) {
          this.requestSentModal = !this.requestSentModal;
        } else if (errors) {
          console.log(errors);
        }
      }
      this.loading = false;
    },
    resetForm() {
      this.$refs.form.resetValidation();
      this.date.modelMenu = false;
      this.date.model = "";
      this.dateTime.modelHour = "";
      this.dateTime.modelTime = "";
      this.state.model = "";
      this.city.model = "";
      this.eventAddress.model = "";
      this.eventType.model = "";
      this.peopleQuantity.model = "";
      this.clientComment.model = "";
      this.isHourValid = false;
      this.isQuantityValid = false;
      this.isClientCommentValid = false;
    },
    handleModal() {
      this.resetForm();
      this.requestSentModal = !this.requestSentModal;
    },
    validateHour(val) {
      this.isHourValid = /^(0?[1-9]|1[0-2]):[0-5][0-9]$/.test(val.target.value);
    },
    validateQuantity(val) {
      this.isQuantityValid = /^[0-9]+$/.test(val) && val.length <= 6;
    },
    validateClientComment(val) {
      this.isClientCommentValid = val.length <= 255;
    },
    dateUnixToString() {
      const secondsInDay = 86400;
      const unixDays = parseInt(this.servicePaymentDeadline) * secondsInDay;
      this.newUnixDay = convertUnixToYYYYMMDD(
        unixDays + Math.floor(Date.now() / 1000)
      ).toString();
      return this.newUnixDay;
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-background-transparent::before {
  background-color: transparent !important;
}

.box-card-shadow {
  box-shadow: 4px 2px 14px 0px rgba(0, 0, 0, 0.35);
}
</style>
